<template>
  <div class="wrap">
    <div>
      <Field
        v-model="phoneNumber"
        center
        label="取包号码"
        placeholder="请输入取包号码，支持模糊查询"
        class="inputWrap"
      />
      <Field
        v-model="orderTradeNo"
        center
        label="订单编号"
        placeholder="请输入订单编号"
        class="inputWrap"
      >
        <template #button>
          <Button size="small" type="primary" @click="onRefresh">搜索</Button>
        </template>
      </Field>
    </div>
    <div class="wrap2">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="conWrap">
              <span class="text1">订单编号</span>
              <span class="text2">{{ item.orderTradeNo || '--' }}</span>
            </div>
            <div class="conWrap">
              <span class="text1">WX单号</span>
              <span class="text2">{{ item.transactionId || '--' }}</span>
            </div>
            <div class="titleWrap">
              <img :src="item.headImgUrl" class="imgWrap" />
              <div class="conWrap">
                <span class="text1">微信名</span>
                <span class="text2">{{ item.nickName || '--' }}</span>
              </div>
              <div class="conWrap">
                <span class="text1">取包号码</span>
                <span class="text2">{{ item.phoneNumber || '--' }}</span>
              </div>
              <div class="conWrap">
                <span class="text1">取包密码</span>
                <span class="text2">{{ item.lockerPassword || '--' }}</span>
              </div>
            </div>
            <div class="conWrap">
              <span class="text1">开柜时间</span>
              <span class="text2">{{ item.lockerOpenTime || '--' }}</span>
            </div>
            <div class="conWrap">
              <span class="text1">场地信息</span>
              <span class="text2">{{ item.lockerAddress || '--' }}</span>
            </div>
            <div class="conWrap">
              <span class="text1">订单来源</span>
              <span class="text2">{{ item.sjlyName || '--' }}</span>
            </div>
            <div class="conWrap">
              <span class="text1">订单状态</span>
              <span class="text2">{{ item.orderStatusName }}</span>
            </div>
            <div class="conWrap">
              <Button style="margin-right: 10px;" type="primary" size="large" @click="openReturn(item)" v-show="!(item.isFree === 1 && !item.transactionId) && (item.orderStatus === 6 || item.orderStatus === 1) && (item.refundStatus === 1 || item.refundStatus === 4 || item.refundStatus === 10 || item.refundStatus === 11)">发起退款</Button>
              <Button type="primary" size="large" @click="showOpen(item)">开柜</Button>
            </div>
            <div class="conWrap" v-show="(item.orderStatus === 6 || item.orderStatus === 1) && (item.refundStatus === 3 || item.refundStatus === 2)">
              <span class="text1">退款状态</span>
              <span class="text2">{{ item.refundStatusName }}</span>
            </div>
          </div>
        </div>
        <Empty description="暂时没有订单" v-else />
      </PullRefresh>
    </div>
    <Dialog
      v-model="show2"
      title="是否对该订单发起退款？"
      :showCancelButton="false"
      :showConfirmButton="false"
      class="modal"
    >
      <Button type="primary" size="large" style="margin: 15px 0" @click="onReturnMoney">是</Button>
      <Button type="primary" size="large" style="background: #e7263c; border: 0" @click="show2=false">否</Button>
    </Dialog>
    <Dialog
      v-model="show3"
      title=""
      :showCancelButton="false"
      :showConfirmButton="false"
      class="modal"
    >
      <div class="boxTxt">当前柜门状态为<span style="color: green">绿色</span>，是否确认打开<span style="color: red">{{ boxNo }}</span>号柜门？</div>
      <Button type="primary" size="large" style="margin: 15px 0" @click="openBox">打开柜门</Button>
      <Button type="primary" size="large" style="background: #e7263c; border: 0" @click="show3=false;boxNo='';deviceId=''">取消</Button>
    </Dialog>
    <Dialog
      v-model="show4"
      title=""
      :showCancelButton="false"
      :showConfirmButton="false"
      class="modal"
    >
      <div class="boxTxt">当前柜门状态为<span style="color: red">使用中</span>，是否确认打开<span style="color: red">{{ boxNo }}</span>号柜门？</div>
      <Button type="primary" size="large" style="margin: 15px 0" @click="openBox">打开柜门</Button>
      <Button type="primary" size="large" style="background: #e7263c; border: 0" @click="show4=false;boxNo='';deviceId=''">取消</Button>
    </Dialog>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading, Field, Button, Dialog } from 'vant'
import axios from 'axios'
export default {
  name: 'orderManage',
  data() {
    return {
      orderTradeNo: '',
      phoneNumber: '',
      nickName: '',
      show: false,
      show2: false,
      show3: false,
      show4: false,
      boxNo: '',
      deviceId: '',
      loading: false,
      orderTradeNo2: '',
      list: []
    }
  },
  created() {
  },
  components: {
    Empty, PullRefresh, Overlay, Loading, Field, Button, Dialog: Dialog.Component
  },
  methods: {
    getData(){
      if (!this.phoneNumber && !this.orderTradeNo && !this.nickName) {
        this.$toast("请填写查询条件后再试！")
        this.loading = false
        return
      }
      this.show = true
      axios.post('/sso/order/list', {
        phoneNumber: this.phoneNumber,
        transactionId: this.orderTradeNo,
        nickName: this.nickName
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result || []
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    showOpen(data){
      console.log(data)
      this.show = true
      axios.post('/sso/locker/selectLockerDoorStatus', {
        boxNo: data.boxNo,
        deviceId: data.lockerId
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const statu = res.data.result
          if (statu === 1) {
            this.show3 = true
            this.boxNo = data.boxNo
            this.deviceId = data.lockerId
          } else if (statu === 2) {
            this.show4 = true
            this.boxNo = data.boxNo
            this.deviceId = data.lockerId
          } else {
            this.$toast('该箱门已损坏，无法开启')
          }
          this.show = false
        } else {
          this.show = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    openReturn(data){
      this.show2 = true
      this.orderTradeNo2 = data.orderTradeNo
    },
    onReturnMoney(){
      this.show2 = false
      this.show = true
      axios.post('/sso/order/refund', {
        userId: this.$store.state.userVo.id,
        orderTradeNo: this.orderTradeNo2
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.status
          if (status) {
            this.$toast.success('退款成功')
          } else{
            this.$toast.fail('退款失败')
          }
          this.getData()
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    openBox(){
      this.show = true
      axios.post('/sso/locker/adminOpeOneBox', {
        deviceId: this.deviceId,
        boxNo: this.boxNo,
        optType: 0,
        isOpen: 1
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.show = false
          this.show3 = false
          this.show4 = false
          this.deviceId = ''
          this.boxNo = ''
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('开门成功')
          } else{
            this.$toast.fail('开门失败')
          }
        } else {
          this.show = false
          this.show3 = false
          this.show4 = false
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 15px;
  }
  /* .inputWrap{
    height: 44px;
  } */
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 4.8rem);
  }
  .boxTxt{
    font-size: 20px;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
    padding: 20px;
  }
  .orderWrap{
    background: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .titleWrap{
    position: relative;
    padding-left: 75px;
  }
  .conWrap{
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
  }
  .text1{
    color: #999393;
    white-space: nowrap
  }
  .text2{
    text-align: right;
    margin-left: 20px;
  }
  .imgWrap{
    width: 65px;
    height: 65px;
    border-radius: 35px;
    position: absolute;
    left: 15px;
    top: 7px;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .modal{
    padding: 10px;
  }
</style>
