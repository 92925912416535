<template>
  <div class="wrap">
    <Field
      v-model="phone"
      center
      label="手机号码"
      placeholder="请输入手机号码"
      class="inputWrap"
    />
    <Field
      v-model="djdesc"
      center
      label="登记原因"
      placeholder="请输入登记原因"
      class="inputWrap2"
    >
      <template #button>
        <Button size="small" type="primary" @click="onRefresh">搜索</Button>
      </template>
    </Field>
    <div class="total">
      登记总数： {{ total }}个
      <Button type="primary" size="small" class="addBtn" @click="show2=true">新增</Button>
    </div>
    <div class="wrap2" id="scrollBox">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="titleWrap">
              <div>手机号码：{{ item.phone || '--' }}</div>
              <div class="yyWrap">登记原因：{{ item.djdesc || '--' }}</div>
              <Button type="primary" class="btn" size="small" @click="show3=true;phone2=item.phone;djdesc2=item.djdesc">删除</Button>
            </div>
          </div>
        </div>
        <Empty description="暂时没有数据" v-else />
      </PullRefresh>
    </div>
    <Dialog
      :value="show2"
      :overlay="true"
      title="新增号码"
      showCancelButton
      showConfirmButton
      @confirm="addPhone"
      @cancel="show2=false"
      class="modal"
    >
      <Field
        v-model="phone2"
        required
        label="手机号码："
        placeholder="请输入手机号码"
      />
      <Field
        v-model="djdesc2"
        required
        label="登记原因："
        placeholder="请输入登记原因"
      />
    </Dialog>
    <Dialog
      :value="show3"
      :overlay="true"
      title="删除号码"
      :message="phone2"
      showCancelButton
      showConfirmButton
      @confirm="deletePhone"
      @cancel="show3=false"
      class="modal"
    />
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading, Field, Button, Dialog } from 'vant'
import axios from 'axios'
export default {
  name: 'phoneList',
  data() {
    return {
      phone: '',
      phone2: '',
      djdesc: '',
      djdesc2: '',
      id: '',
      show: false,
      show2: false,
      show3: false,
      refreshing: false,
      loading: false,
      finished: true,
      list: [],
      total: 0
    }
  },
  created() {
    this.getData()
  },
  components: {
    Empty, PullRefresh, Overlay, Loading, Field, Button, Dialog: Dialog.Component
  },
  methods: {
    getData(){
      this.show = true
      axios.post('/sso/phone/pageList',{
        pageNo: 1,
        pageSize: 10,
        phone: this.phone,
        djdesc: this.djdesc
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          let list = res.data.result.data || []
          this.total = res.data.result.totalCount || 0
          this.list = list
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    addPhone(){
      if (!this.phone2 || !this.djdesc2) {
        this.$toast.fail('请填写手机号码和登记原因！')
        return
      }
      this.show = true
      axios.post('/sso/phone/addPhoneRecord', {
        phone: this.phone2,
        djdesc: this.djdesc2
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.status
          if (status) {
            this.$toast.success('新增成功')
            this.show2 = false
            this.phone2 = ''
            this.djdesc2 = ''
            this.getData()
          } else{
            this.$toast.fail('新增失败')
            this.show2 = false
          }
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    deletePhone(){
      if (!this.phone2) {
        this.$toast.fail('删除失败！')
        return
      }
      this.show = true
      axios.post('/sso/phone/deletePhoneRecord', {
        phone: this.phone2,
        djdesc: this.djdesc2
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.status
          if (status) {
            this.$toast.success('删除成功')
            this.show3 = false
            this.phone2 = ''
            this.djdesc2 = ''
            this.getData()
          } else{
            this.$toast.fail('删除失败')
            this.show3 = false
          }
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 140px;
  }
  .total{
    position: absolute;
    top: 90px;
    color: #0d1a13;
    font-size: 0.42667rem;
    font-weight: bolder;
    width: 100%;
    border: 1px solid #09b966;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 9px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 4.55rem);
  }
  .inputWrap{
    position: absolute;
    height: 35px;
  }
  .inputWrap2{
    position: absolute;
    height: 35px;
    top: 40px;
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 5px;
  }
  .titleWrap{
    padding: 10px;
    /* display: flex;
    justify-content: space-between; */
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 1px solid #f2f3f5;
    color: #fff;
    position: relative;
  }
  .yyWrap{
    margin-top: 5px;
  }
  .conWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    position: relative;
  }
  .conWrap:last-child{
    border-bottom: 0;
  }
  .text1{
    color: #fff;
    display: inline-block;
    line-height: 32px;
  }
  .status{
    color: #1c19fa;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .btn{
    background-color: #0744c1;
    border-radius: 5px;
    border: 0;
    right: 20px;
    position: absolute;
    top: 5px;
  }
  .topBtn{
    background-color: #0744c1;
    border-radius: 5px;
    border: 0;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radioWrap{
    display: flex;
    font-size: 14px;
    height: 44px;
    align-items: center;
    border-bottom: 0.02667px solid #ebedf0;
    margin: 0 10px;
  }
  .addBtn{
    position: absolute;
    right: 15px;
  }
</style>
