<template>
  <div class="wrap">
    <Field
      v-model="name"
      center
      label="场地"
      placeholder="请输入场地名称"
      class="inputWrap"
    >
      <template #button>
        <Button size="small" type="primary" @click="onRefresh">搜索</Button>
      </template>
    </Field>
    <div class="wrap2" id="scrollBox">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="titleWrap">
              <span>{{ item.areaName || '--' }}</span>
            </div>
            <div v-for="(ele, j) in item.lockerList" :key="j" class="conWrap" @click="goDetail(ele, item)">
              <span class="text1">{{ele.lockerName}}&nbsp;-&nbsp;<span :style="{color: colorMap[ele.lockerStatus]}">{{ statusMap[ele.lockerStatus] }}</span></span>
              <span class="text1">管理柜门</span>
            </div>
            <div style="display: none;">
              <Button size="large" type="info" class="topBtn2" @click="changeStatus(item.areaCode)">手动更新柜门状态</Button>
            </div>
          </div>
        </div>
        <Empty description="暂时没有订单" v-else />
      </PullRefresh>
    </div>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading, Field, Button } from 'vant'
import axios from 'axios'
const statusMap = {
  1: '在线',
  2: '不在线'
}
const colorMap = {
  1: '#1c19fa',
  2: '#302727'
}
export default {
  name: 'boxManage',
  data() {
    return {
      name: '',
      refreshing: false,
      loading: false,
      finished: true,
      list: [],
      statusMap,
      colorMap,
      scrollY: 0
    }
  },
  created() {
    this.getData()
  },
  activated() {
    document.querySelector('#scrollBox').scrollTop = this.scrollY
  },
  deactivated() {
  },
  components: {
    Empty, PullRefresh, Overlay, Loading, Field, Button
  },
  methods: {
    getData(code){
      this.show = true
      this.loading = true
      axios.post('/sso/static/getPageAreaList').then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const list = res.data.result || []
          this.list = list.filter(item => item.areaName.indexOf(this.name) > -1)
          this.show = false
          this.loading = false
          if (code) {
            this.scrollY = 0
            document.querySelector('#scrollBox').scrollTop = 0
          }
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    changeStatus(areacode){
      this.show = true
      this.loading = true
      axios.post('/sso/static/adminByUpdateLockerStatus', { lockerAreaCode: areacode }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.$toast("更新成功！")
          this.getData()
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData(1)
    },
    goDetail(data, record){
      this.scrollY = document.querySelector('#scrollBox').scrollTop
      const name = window.encodeURIComponent(record.areaName + '·' + data.lockerName)
      this.$router.push(`/doorDetail/${data.lockerId}?name=` + name)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 45px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 2.13333rem);
  }
  .inputWrap{
    position: absolute;
    height: 35px;
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
  }
  .titleWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 1px solid #f2f3f5;
    color: #fff;
    align-items: center;
  }
  .conWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
  }
  .conWrap:last-child{
    border-bottom: 0;
  }
  .text1{
    color: #fff;
  }
  .status{
    color: #1c19fa;
  }
  .topBtn2{
    background-color: forestgreen;
    height: 0.8rem;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
