<template>
  <div class="wrap">
    <div>
      <Field
        v-model="phoneNumber"
        center
        label="取包号码"
        placeholder="请输入取包号码，支持模糊查询"
        class="inputWrap"
      />
      <Field
        v-model="orderTradeNo"
        center
        label="订单编号"
        placeholder="请输入订单编号"
        class="inputWrap"
      >
        <template #button>
          <Button size="small" type="primary" @click="onRefresh">搜索</Button>
        </template>
      </Field>
    </div>
    <div class="wrap2">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="conWrap">
              <span class="text1">订单编号</span>
              <span class="text2">{{ item.orderTradeNo }}</span>
            </div>
            <div class="titleWrap">
              <img :src="item.headImgUrl" class="imgWrap" />
              <div class="conWrap">
                <span class="text1">微信名</span>
                <span class="text2">{{ item.nickName }}</span>
              </div>
              <div class="conWrap">
                <span class="text1">取包号码</span>
                <span class="text2">{{ item.phoneNumber }}</span>
              </div>
              <div class="conWrap">
                <span class="text1">取包密码</span>
                <span class="text2">{{ item.lockerPassword }}</span>
              </div>
            </div>
            <div class="conWrap">
              <span class="text1">开柜时间</span>
              <span class="text2">{{ item.lockerOpenTime }}</span>
            </div>
            <div class="conWrap">
              <span class="text1">场地信息</span>
              <span class="text2">{{ item.lockerAddress }}</span>
            </div>
            <div class="conWrap">
              <span class="text1">订单状态</span>
              <span class="text2">{{ item.orderStatusName }}</span>
            </div>
          </div>
        </div>
        <Empty description="暂时没有订单" v-else />
      </PullRefresh>
    </div>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading, Field, Button } from 'vant'
import axios from 'axios'
export default {
  name: 'orderManage',
  data() {
    return {
      orderTradeNo: '',
      phoneNumber: '',
      nickName: '',
      show: false,
      loading: false,
      list: []
    }
  },
  created() {
  },
  components: {
    Empty, PullRefresh, Overlay, Loading, Field, Button
  },
  methods: {
    getData(){
      if (!this.phoneNumber && !this.orderTradeNo && !this.nickName) {
        this.$toast("请填写查询条件后再试！")
        this.loading = false
        return
      }
      this.show = true
      axios.post('/sso/order/list', {
        phoneNumber: this.phoneNumber,
        orderTradeNo: this.orderTradeNo
        // nickName: this.nickName
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result || []
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    goDetail(data){
      this.$router.push(`/orderDetail/${data.orderTradeNo}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 15px;
  }
  /* .inputWrap{
    height: 44px;
  } */
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 4.8rem);
  }
  .orderWrap{
    background: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .titleWrap{
    position: relative;
    padding-left: 75px;
  }
  .conWrap{
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
  }
  .text1{
    color: #999393;
  }
  .imgWrap{
    width: 65px;
    height: 65px;
    border-radius: 35px;
    position: absolute;
    left: 15px;
    top: 7px;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
</style>
