<template>
  <div class="wrap">
    <PullRefresh
      success-text="刷新成功"
      v-model="loading"
      @refresh="getData"
      class="refresh"
    >
      <div class="box">
        <div class="topWrap">
          <span class="nameWrap"><Icon name="manager" size="20" />{{$store.state.userVo.nickName}}</span>
          <Button plain type="primary" size="small" @click="outLogin">退出登陆</Button>
        </div>
        <div class="midWrap">
          <div class="titleWrap">今日单量</div>
          <div class="countWrap">
            <div>
              <div>
                <div>进行中</div>
                <div>{{ list.ingOrderCount || 0 }}</div>
              </div>
              <div>
                <div>已完成</div>
                <div>{{ list.finishOrderCount || 0 }}</div>
              </div>
              <div>
                <div>总单量</div>
                <div>{{ list.totalOrderCount || 0 }}</div>
              </div>
            </div>
            <div>
              <div>
                <div>昨日单量</div>
                <div>{{ list.yestOrderCount || 0 }}</div>
              </div>
              <div>
                <div>本月单量</div>
                <div>{{ list.nowMonthCount || 0 }}</div>
              </div>
              <div>
                <div>上月单量</div>
                <div>{{ list.lastMonthCount || 0 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="botWrap">
          <div class="titleWrap2">管理工具</div>
          <div class="cardWrap" v-if="$store.state.menuList && $store.state.menuList.length">
            <div v-for="(item, index) in $store.state.menuList" :key="index" @click="goPage(item.menuCode)" >
              <div class="iconWrap">
                <i :class="`iconfont ${item.menuIcon}`" />
              </div>
              <div class="textWrap">{{item.menuName}}</div>
            </div>
          </div>
          <div v-else class="emptyWrap">暂无数据</div>
        </div>
      </div>
    </PullRefresh>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
    <Dialog
      v-model="show2"
      title="请输入密码验证"
      showCancelButton
      showConfirmButton
      @confirm="onpaymentyes"
    >
      <div>
        <!-- 密码输入框 -->
        <Field
          v-model="pwd"
          type="digit"
          label="密码"
          placeholder="请输入6位数密码"
        />
      </div>
    </Dialog>
    <!-- <a class="bah" href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2022008234号-2</a> -->
  </div>
</template>

<script>
import { PullRefresh, Button, Icon, Overlay, Loading, Dialog, Field } from 'vant'
import axios from 'axios'
const yzPATHS = ['dataManage', 'placeSetting'] // 'returnOrderList', 'returnOrderList2', 

export default {
  name: 'userPage',
  data() {
    return {
      show: false,
      show2: false,
      loading: false,
      list: {},
      pwd: '',
      path: '',
      isDev: process.env.NODE_ENV === 'development'
    }
  },
  created() {
    this.getData()
  },
  components: {
    PullRefresh, Button, Icon, Overlay, Loading, Dialog: Dialog.Component, Field
  },
  methods: {
    getData(){
      this.show = true
      axios.post('/sso/order/centerOrderCount').then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result || {}
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onpaymentyes(){
      if (!this.pwd || this.pwd.length !== 6) {
        this.$toast.fail('请输入正确位数的密码后再试')
        return
      }
      axios.post('/sso/locker/checkOpenLockPwd', {
        password: this.pwd
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            this.pwd = ''
            this.$router.push(`/${this.path}`)
            this.path = ''
          } else{
            this.$toast.fail('密码错误')
            this.pwd = ''
          }
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    goPage(path){
      if(path){
        if(yzPATHS.includes(path)) {
          this.show2 = true
          this.path = path
        } else {
          this.$router.push(`/${path}`)
        }
      }
    },
    outLogin(){
      this.$router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: auto;
    /* background: #fff; */
    font-size: 14px;
  }
  .box{
    min-height: calc(100vh - 0.8rem)
  }
  .topWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nameWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
  }
  .refresh{
    height: auto;
  }
  .midWrap{
    background: #1bb13b;
    color: #fff;
    margin-top: 24px;
    padding: 20px 25px;
    border-radius: 10px;
  }
  .titleWrap{
    text-align: center;
    font-size: 15px;
    font-weight: bolder;
  }
  .titleWrap2{
    font-size: 15px;
    font-weight: bolder;
    padding: 10px;
  }
  .countWrap{
    margin-top: 15px;
  }
  .countWrap > div{
    display: flex;
  }
  .countWrap > div > div{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px
  }
  .countWrap > div:nth-child(1){
    border-bottom: 1px solid #fff;
  }
  .countWrap > div > div > div:nth-child(2){
    font-size: 16px;
    font-weight: bolder;
    margin-top: 10px;
  }
  .botWrap{
    background: #fff;
    border-radius: 10px;
    margin-top: 30px;
    overflow: hidden;
  }
  .cardWrap{
    overflow: hidden;
    border-top: 1px solid #f2f3f5;
  }
  .cardWrap > div{
    float: left;
    width: 33.33%;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f3f5;
    border-right: 1px solid #f2f3f5;
    text-align: center;
    padding: 10px 0;
    position: relative;
  }
  .cardWrap > div:nth-child(3){
    border-right: 0;
  }
  .cardWrap > div:nth-child(6){
    border-right: 0;
  }
  /* .cardWrap > div::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 0 solid #f2f3f5;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    border-width: 0 1px 1px 0;
  } */
  .iconWrap{
    margin-bottom: 5px;
  }
  .iconWrap > i{
    font-size: 28px;
    color: #07c160;
  }
  .emptyWrap{
    padding: 60px;
    text-align: center;
    border-top: 1px solid #f7efef;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bah{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #000;
  }
</style>
